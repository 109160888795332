import { GET_CATEGORIES, SET_CATEGORIES } from '../types';

const initialState = {
  categories: [],
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
    case SET_CATEGORIES:
      return { ...state, categories: action.categories };
    default:
      return state;
  }
};

export default categories;
