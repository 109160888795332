import { USER_LOGIN } from '../types';

const initialState = {
  user: undefined,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        user: {
          ...action.user,
          email: action.email,
          password: action.password,
          device_id: action.device_id,
        },
      };
    default:
      return state;
  }
};

export default login;
