import { message } from 'antd';
import { callApi } from '../../service';

const saveOpenOrder = async (data, cancelSource) => {
  const response = await callApi({
    api_type: 'order_live',
    db: data.database,
    email: data.email,
    password: data.password,
    url: data.url,
    req_arg: [{ data: data.data }],
    cancelSource,
  });

  if (
    typeof response.data?.result === 'string' &&
    response.data?.result?.toLowerCase() ===
      'Session closed please login again'.toLowerCase()
  ) {
    return message.error(response.data?.result, 1);
  }

  return response?.data.result;
};

export { saveOpenOrder };
