import { GET_CUSTOMERS_LAST_ORDER, SET_CUSTOMERS_LAST_ORDER } from '../types';

const initialState = {
  customersLastOrders: [],
  complete: false,
};

const customersLastOrders = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_LAST_ORDER:
      return {
        ...state,
        customersLastOrders: action.customersLastOrders,
        complete: action.complete,
      };
    case SET_CUSTOMERS_LAST_ORDER:
      return {
        ...state,
        customersLastOrders: action.customersLastOrders,
        complete: action.complete,
      };
    default:
      return state;
  }
};

export default customersLastOrders;
