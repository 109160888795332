import { SET_SAVE_FOR_LATER } from "../types";

const initialState = {
  isViewAllOrdersSelected: false,
  showDbOrders: true,
};

const isViewAllOrdersSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_SAVE_FOR_LATER:
      return {
        ...state,
        isViewAllOrdersSelected: action.isViewAllOrdersSelected,
        showDbOrders: action.showDbOrders,
      };
    default:
      return state;
  }
};

export default isViewAllOrdersSelected;
