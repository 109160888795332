import { GET_CLOSING_REPORT_FILTERS } from '../types';

const initialState = {
  closingReportFilters: undefined,
};

const closingReportFilters = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLOSING_REPORT_FILTERS:
      return { ...state, closingReportFilters: action.closingReportFilters };
    default:
      return state;
  }
};

export default closingReportFilters;
