import { MAX_RECORDS_FROM_INDEXEDDB } from '../../constants';
import { GET_PRODUCTS } from '../types';

const initialState = {
  products: [],
  renderedProductsCount: MAX_RECORDS_FROM_INDEXEDDB,
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.products,
        renderedProductsCount:
          action.renderedProductsCount || MAX_RECORDS_FROM_INDEXEDDB,
      };
    default:
      return state;
  }
};

export default products;
