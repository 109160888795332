import { SET_DISCARD_PRODUCT_CLICK } from '../types';

const initialState = {
  status: false,
  discardProductDebounceTerm: undefined,
};

const discardProductClicked = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISCARD_PRODUCT_CLICK:
      return {
        ...state,
        status: action.status,
        discardProductDebounceTerm: action.debounceTerm,
      };
    default:
      return state;
  }
};

export default discardProductClicked;
