import { FETCH_SAVED_ORDERS } from "../types";

const initialState = {
  savedOrders: [],
};

const savedOrders = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SAVED_ORDERS:
      return { ...state, savedOrders: action.savedOrders };
    default:
      return state;
  }
};

export default savedOrders;
