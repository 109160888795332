import { SET_SAVE_FOR_LATER_OFFLINE } from "../types";

const initialState = {
  orders: [],
};

const saveForLaterOffline = (state = initialState, action) => {
  let orders;
  if (
    state.orders.length &&
    state.orders.find((o) => o.name === action.order?.name)
  ) {
    if (action.deleteOrder) {
      orders = state.orders.filter((o) => o.name !== action.order?.name);
    } else {
      orders = state.orders.map((o) =>
        o.name === action.order?.name
          ? { ...o, lines: [...action.order?.lines] }
          : o
      );
    }
  } else {
    orders = [...state.orders, action.order];
  }

  switch (action.type) {
    case SET_SAVE_FOR_LATER_OFFLINE:
      return { ...state, orders };
    default:
      return state;
  }
};

export default saveForLaterOffline;
