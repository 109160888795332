import { FETCH_CLOSING_REPORT } from '../types';

const initialState = {
  report: [],
};

const closingReport = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLOSING_REPORT:
      return { ...state, report: action.report };
    default:
      return state;
  }
};

export default closingReport;
