import { SET_CUSTOMERS_PREVIOUS_ORDERS_VIEW } from '../types';

const initialState = {
  status: false,
  fromCustomersPage: false,
};

const isCustomerPreviousOrdersViewSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS_PREVIOUS_ORDERS_VIEW:
      return {
        ...state,
        status: action.status,
        fromCustomersPage: action.fromCustomersPage,
        isCustomerAlreadySelected: action.isCustomerAlreadySelected,
      };
    default:
      return state;
  }
};

export default isCustomerPreviousOrdersViewSelected;
