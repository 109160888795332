import { generateId } from '../../utils';
import { SET_ORDER } from '../types';

const getInitialState = () => {
  const key = generateId();

  return {
    order: {
      name: key,
      lines: [],
      orderType: undefined,
      unique_order_key: key,
    },
  };
};

const order = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_ORDER:
      const order = {
        ...action.order,
        lines: action.order.lines?.map((oL) => ({
          ...oL,
          temporaryId: generateId() + Math.random(Math.random()),
        })),
      };

      return {
        ...state,
        order: { ...order },
      };
    default:
      return state;
  }
};

export default order;
