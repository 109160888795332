import { SET_DISABLE_CUSTOMER } from '../types';

const initialState = {
  status: false,
};

const disableCustomer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISABLE_CUSTOMER:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default disableCustomer;
