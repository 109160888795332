import { GET_INVOICE_SEARCH } from "../types";

const initialState = {
  invoice: undefined,
};

const invoice = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE_SEARCH:
      return { ...state, invoice: action.invoice };
    default:
      return state;
  }
};

export default invoice;
