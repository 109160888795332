import { SET_ORDER_TYPE } from '../types';

const initialState = {
  orderType: undefined,
};

const orderType = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_TYPE:
      return { ...state, orderType: action.orderType };
    default:
      return state;
  }
};

export default orderType;
