import { GET_CAR_BRANDS } from "../types";

const initialState = {
  carBrands: [],
};

const carBrands = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAR_BRANDS:
      return { ...state, carBrands: action.carBrands };
    default:
      return state;
  }
};

export default carBrands;
