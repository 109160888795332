import { SET_TABLE_VIEW } from "../types";

const initialState = {
  tableView: {
    status: false,
    floor_id: undefined,
    table_id: undefined,
  },
};

const tableView = (state = initialState, action) => {
  switch (action.type) {
    case SET_TABLE_VIEW:
      return {
        ...state,
        tableView: action.tableView,
      };
    default:
      return state;
  }
};

export default tableView;
