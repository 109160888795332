import {
  GET_CUSTOMERS_PREVIOUS_ORDERS,
  SET_CUSTOMERS_PREVIOUS_ORDERS,
} from '../types';

const initialState = {
  previousOrders: undefined,
};

const previousOrders = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_PREVIOUS_ORDERS:
    case SET_CUSTOMERS_PREVIOUS_ORDERS:
      return { ...state, previousOrders: action.previousOrders };
    default:
      return state;
  }
};

export default previousOrders;
