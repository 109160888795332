import { FETCH_SMS_LOG } from '../types';

const initialState = {
  smsLog: [],
};

const smsLog = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SMS_LOG:
      return { ...state, smsLog: action.smsLog };
    default:
      return state;
  }
};

export default smsLog;
