import { GET_SALESMEN_INFO } from "../types";

const initialState = {
  salesmenInfo: [],
};

const salesmenInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALESMEN_INFO:
      return { ...state, salesmenInfo: action.salesmenInfo };
    default:
      return state;
  }
};

export default salesmenInfo;
