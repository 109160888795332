import { GET_TAX_CLASSES } from '../types';

const initialState = {
  taxClasses: undefined,
};

const taxClasses = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAX_CLASSES:
      return { ...state, taxClasses: action.taxClasses };
    default:
      return state;
  }
};

export default taxClasses;
