import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Loader from './components/Loader';

import './global.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ContextProvider } from './Contexts/SocketContext';

const Home = lazy(() => import('./pages/Home'));
const SelectShop = lazy(() => import('./pages/SelectShop'));
const Pos = lazy(() => import('./pages/Pos'));
const Kds = lazy(() => import('./pages/Kds'));
const SelectPosOrKds = lazy(() => import('./pages/SelectPosOrKds'));
const P404 = lazy(() => import('./pages/404'));

function App() {
  return (
    <Router>
      <ContextProvider>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/select-pos-or-kds' component={SelectPosOrKds} />
            <Route exact path='/select-shop' component={SelectShop} />
            <Route exact path='/pos' component={Pos} />
            <Route exact path='/kds' component={Kds} />
            <Route path='*' component={P404} />
          </Switch>
        </Suspense>
      </ContextProvider>
    </Router>
  );
}

export default App;
