import { GET_CUSTOMERS_POINTS } from '../types';

const initialState = {
  customersPoints: [],
};

const customersPoints = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_POINTS:
      return { ...state, customersPoints: action.customersPoints };
    default:
      return state;
  }
};

export default customersPoints;
