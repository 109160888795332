import { SET_TODAYS_ORDERS } from "../types";

const initialState = {
  isViewTodaysOrdersSelected: false,
};

const isViewTodaysOrdersSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_TODAYS_ORDERS:
      return {
        ...state,
        isViewTodaysOrdersSelected: action.isViewTodaysOrdersSelected,
      };
    default:
      return state;
  }
};

export default isViewTodaysOrdersSelected;
