import { message } from 'antd';

import { callApi } from '../../service';

const saveForLater = async (
  data,
  showMessage = true,
  syncMultiple = false,
  user
) => {
  let response;
  if (syncMultiple) {
    response = await callApi({
      api_type: 'save_later',
      db: user.database,
      email: user.email,
      password: user.password,
      url: user.url,
      req_arg: data,
    });
  } else {
    response = await callApi({
      api_type: 'save_later',
      db: data.database,
      email: data.email,
      password: data.password,
      url: data.url,
      req_arg: [{ data: data.data }],
    });
  }

  if (response?.data?.error) {
    if (!showMessage) return 'error';

    return message.error(
      `${response.data.error.message}, Save For Later Failed!`,
      1
    );
  }

  if (
    typeof response.data?.result === 'string' &&
    response.data?.result?.toLowerCase() ===
      'Session closed please login again'.toLowerCase()
  ) {
    return message.error(response.data?.result, 1);
  }

  showMessage && message.success(response.data.result, 1);

  return 'success';
};

export { saveForLater };
