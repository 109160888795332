import { SET_FLOORS } from "../types";

const initialState = {
  oilFloors: [],
};

const oilFloors = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLOORS:
      return { ...state, oilFloors: action.oilFloors };
    default:
      return state;
  }
};

export default oilFloors;
