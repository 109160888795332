import { GET_INSTRUCTION_INFO } from "../types";

const initialState = {
  instructionInfo: [],
};

const instructionInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTRUCTION_INFO:
      return { ...state, instructionInfo: action.instructionInfo };
    default:
      return state;
  }
};

export default instructionInfo;
