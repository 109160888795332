import { SET_PAYMENT_LIST } from "../types";

const initialState = {
  paymentList: [],
};

const paymentList = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_LIST:
      return { ...state, paymentList: action.paymentList };
    default:
      return state;
  }
};

export default paymentList;
