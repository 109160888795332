import { GET_SALESMEN } from "../types";

const initialState = {
  salesmen: [],
};

const salesmen = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALESMEN:
      return { ...state, salesmen: action.salesmen };
    default:
      return state;
  }
};

export default salesmen;
