import React, { createContext, useState, useRef, useEffect, memo } from 'react';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';

const SocketContext = createContext();
// const socket = io('http://localhost:5000', {
//   autoConnect: false,
// });
const socket = io('https://nodetest.visiomate.com', {
  autoConnect: false,
});
const ContextProvider = ({ children }) => {
  const [name, setName] = useState('');
  const [me, setMe] = useState('');
  const configInfo = useSelector((state) => state.configInfo.configInfo);
  const user = useSelector((state) => state.login.user);



  const makeConnection = (user, configInfo, screenType) => {
    socket.io.opts.query = {
      user: JSON.stringify(user),
      sessionId: configInfo?.session_id,
      screenType: screenType,
    };

    socket.connect();

    socket.on('disconnect', () => {
    });
  };

  useEffect(() => {
    if (configInfo && user) {
      socket.on('connection-success', (id) => {
        setMe(id);
      });
    }
  }, [configInfo, user]);

  return (
    <SocketContext.Provider
      value={{
        makeConnection,
        socket,
        name,
        setName,
        me,

      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
