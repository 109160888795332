import React from "react";
import { Alert } from "antd";

const { ErrorBoundary } = Alert;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? (
      <ErrorBoundary>Something went wrong...</ErrorBoundary>
    ) : (
      this.props.children
    );
  }
}

export default Index;
