import { SET_SELECTED_ORDER_LINE } from '../types';

const initialState = {
  selectedOrderLine: undefined,
};

const selectedOrderLine = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ORDER_LINE:
      return {
        ...state,
        selectedOrderLine: action.selectedOrderLine,
      };
    default:
      return state;
  }
};

export default selectedOrderLine;
