import { GET_KDS_ORDERS, SET_KDS_ORDERS } from '../types';

const initialState = {
  kdsOrders: {},
};

const kdsOrders = (state = initialState, action) => {
  switch (action.type) {
    case GET_KDS_ORDERS:
    case SET_KDS_ORDERS:
      return {
        ...state,
        kdsOrders: action.kdsOrders,
      };
    default:
      return state;
  }
};

export default kdsOrders;
