import { SET_SELECTED_VIEW } from "../types";

const initialState = {
  selectedView: undefined,
};

const selectedView = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_VIEW:
      return { ...state, selectedView: action.selectedView };
    default:
      return state;
  }
};

export default selectedView;
