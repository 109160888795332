import { GET_CARS, SET_CARS } from '../types';

const initialState = {
  cars: [],
};

const cars = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARS:
      return { ...state, cars: action.cars };
    case SET_CARS:
      return { ...state, cars: action.cars };
    default:
      return state;
  }
};

export default cars;
