import { SET_CANCEL_TOKEN } from '../types';

const initialState = {
  cancelToken: undefined,
};

const cancelToken = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANCEL_TOKEN:
      return {
        ...state,
        cancelToken: action.cancelToken,
      };
    default:
      return state;
  }
};

export default cancelToken;
