import { useState, useEffect } from 'react';
import { Button, Card, Empty, Input, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import { getCustomers } from '../../store/actions/get/customers';
import { setCustomersView } from '../../store/actions/set/customersView';
import { getCars } from '../../store/actions/get/cars';

import Loader from '../Loader';

import './jobcardmodal.scss';
import { OIL_REST_HOTEL } from '../../constants';
import { setOilFloors } from '../../store/actions/set/oilFloors';

const { Title } = Typography;

const loaderStyles = {
  width: '100%',
  height: '100%',
  display: 'grid',
  placeItems: 'center',
  marginTop: '2rem',
};

const Index = ({ hideModalAction }) => {
  const dispatch = useDispatch();
  const selectedPos = useSelector((state) => state.selectedPos.selectedPos);
  const [showLoader, setShowLoader] = useState(false);
  const [showCarsLoader, setShowCarsLoader] = useState(false);
  const user = useSelector((state) => state.login.user);
  const customers = useSelector((state) => state.customers.customers);
  const cars = useSelector((state) => state.cars.cars);
  const oilFloors = useSelector((state) => state.oilFloors?.oilFloors);
  const floors = useSelector((state) => state.floors?.floors);
  const tableView = useSelector((state) => state.tableView?.tableView);
  const [searchText, setSearchText] = useState('');
  const [allSearchedCars, setAllSearchedCars] = useState([]);
  const [searchType, setSearchType] = useState('phone');

  useEffect(() => {
    if (!customers.length) {
      setShowLoader(true);
      dispatch(getCustomers({ ...user, posType: selectedPos?.pos_type }));
    }
    if (customers.length && !cars.length) {
      setShowLoader(false);
      setShowCarsLoader(true);
      dispatch(getCars(user));
    }
    if (customers.length && cars.length) {
      setShowCarsLoader(false);
    }
  }, [customers, cars]);

  const handleSearch = () => {
    let searchedCustomers;
    let searchedCars = [];
    if (searchType === 'phone') {
      searchedCustomers = customers?.find(
        (customer) => customer.phone === searchText
      );
      if (searchedCustomers && cars.length) {
        searchedCustomers?.cars_id?.map((car_id) => {
          searchedCars = [
            ...searchedCars,
            cars?.find((car) => car.car_id === Number(car_id)),
          ];
        });
      }
    }
    if (searchType === 'vehicle') {
      searchedCars = cars?.filter(
        (car) => car.vehicle_no.toLowerCase() === searchText.toLowerCase()
      );
    }
    setAllSearchedCars(searchedCars);
  };

  const handleCarSelect = (car) => {
    const clickedCustomer = customers?.find(
      (customer) => customer.id === car.partner_id
    );

    if (clickedCustomer && selectedPos?.pos_type === OIL_REST_HOTEL) {
      const newFloors = (oilFloors?.length ? oilFloors : floors)?.map((f) => {
        if (f.id === tableView.floor_id) {
          f.tables?.forEach((t) => {
            if (t.id === tableView.table_id) {
              t.customer = {
                ...clickedCustomer,
                car_id: car.car_id,
                car,
              };
            }
          });
        }

        return f;
      });
      dispatch(setOilFloors([...newFloors]));
      hideModalAction();
    }
  };

  return (
    <div className='jobs-card-modal-wrapper'>
      <Title level={3} className='title'>
        Select Job Card
      </Title>

      {showLoader ? (
        <Loader
          tip='Fetching Customers, It may takes several minutes! Please Wait...'
          style={loaderStyles}
        />
      ) : showCarsLoader ? (
        <Loader
          tip='Fetching Cars, It may takes several minutes! Please Wait...'
          style={loaderStyles}
        />
      ) : (
        <div>
          <div className='filter-btns'>
            <Button
              type={`${searchType === 'phone' ? 'primary' : 'secondary'}`}
              size='large'
              onClick={() => setSearchType('phone')}
            >
              Phone #
            </Button>
            <Button
              type={`${searchType === 'vehicle' ? 'primary' : 'secondary'}`}
              size='large'
              onClick={() => setSearchType('vehicle')}
            >
              Car #
            </Button>
          </div>
          <div className='top-wrapper'>
            <div className='search-wrapper'>
              <Input
                placeholder={`Enter ${searchType} #`}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <Button type='primary' size='large' onClick={handleSearch}>
                Search
              </Button>
            </div>

            <Button
              type='primary'
              size='large'
              onClick={() => dispatch(setCustomersView(true, true))}
            >
              <PlusOutlined />
            </Button>
          </div>
          {allSearchedCars.length ? (
            <div className='cards-wrapper'>
              {allSearchedCars.map(
                (car) =>
                  car !== undefined && (
                    <Card onClick={() => handleCarSelect(car)} hoverable>
                      {car?.vehicle_no}
                    </Card>
                  )
              )}
            </div>
          ) : (
            <Empty description='No Cars' />
          )}
        </div>
      )}
    </div>
  );
};

export default Index;
