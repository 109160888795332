import { SET_PAYMENT_VIEW } from "../types";

const initialState = {
  status: false,
};

const paymentView = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_VIEW:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default paymentView;
