import { SET_CUSTOMER } from "../types";

const initialState = {
  customer: undefined,
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.customer,
      };
    default:
      return state;
  }
};

export default customer;
