import { SET_HOURLY_REPORT_VIEW } from '../types';

const initialState = {
  status: false,
};

const isHourlyReportViewSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_HOURLY_REPORT_VIEW:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default isHourlyReportViewSelected;
