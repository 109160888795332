import { message } from 'antd';

import { callApi } from '../../service';
import { FETCH_SAVED_ORDERS } from '../../types';

const getSavedOrders = (data) => async (dispatch) => {
  const response = await callApi({
    api_type: 'fetch_saved_orders',
    db: data.database,
    email: data.email,
    password: data.password,
    url: data.url,
    req_arg: {
      session_id: data.sessionId,
      pos_type: data.PosType,
      floor_id: data.floor_id || false,
      table_id: data.table_id || false,
    },
  });

  if (response?.data?.error) {
    // message.error(
    //   `${response.data.error.message}, Fetch Saved Orders Failed!`,
    //   1
    // );
    return;
  }
  if (response?.data?.result && !response.data.result?.length) {
    // message.info('Oops, Saved Orders Not Found!', 1);
    return;
  }
  if (
    typeof response.data?.result === 'string' &&
    response.data?.result?.toLowerCase() ===
    'Session closed please login again'.toLowerCase()
  ) {
    return message.error(response.data?.result, 1);
  }

  dispatch({ type: FETCH_SAVED_ORDERS, savedOrders: response?.data?.result });
};

export { getSavedOrders };
