import { SET_SYNCING_IN_LIVE_MODE } from '../types';

const initialState = {
  status: false,
};

const isSyncingInProgressInLiveMode = (state = initialState, action) => {
  switch (action.type) {
    case SET_SYNCING_IN_LIVE_MODE:
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
};

export default isSyncingInProgressInLiveMode;
