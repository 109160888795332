import { GET_CUSTOMERS, SET_CUSTOMERS } from '../types';

const initialState = {
  customers: [],
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return { ...state, customers: action.customers };
    case SET_CUSTOMERS:
      return { ...state, customers: action.customers };
    default:
      return state;
  }
};

export default customers;
