import React from 'react';
import { Spin } from 'antd';

const loaderStyles = {
  width: '100vw',
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
};

const Index = ({ style, tip }) => {
  return (
    <div style={style || loaderStyles}>
      <Spin size='large' tip={`${tip || ''}`} />
    </div>
  );
};

export default Index;
