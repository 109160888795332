import { GET_CONFIG_INFO } from "../types";

const initialState = {
  configInfo: undefined,
};

const configInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG_INFO:
      return { ...state, configInfo: action.configInfo };
    default:
      return state;
  }
};

export default configInfo;
