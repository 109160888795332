import { SET_SELECTED_POS } from "../types";

const initialState = {
  selectedPos: undefined,
};

const selectedPos = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_POS:
      return { ...state, selectedPos: action.selectedPos };
    default:
      return state;
  }
};

export default selectedPos;
