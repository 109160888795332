import { SET_DEBOUNCE_TERM } from '../types';

const initialState = {
  debounceTerm: undefined,
  value: undefined,
};

const debounceTerm = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEBOUNCE_TERM:
      return {
        ...state,
        debounceTerm: action.debounceTerm,
        value: action.value,
      };
    default:
      return state;
  }
};

export default debounceTerm;
