import { message } from 'antd';
import { getTotal } from './components/OrdersQueue';
import { MAX_RECORDS_FROM_INDEXEDDB } from './constants';

// Better to use more than 6 characters
const generateId = () => {
  let id = ['', '', ''];
  const characters = '0123456789';
  for (let x = 0; x <= 2; x++) {
    for (let i = 0; i < 4; i++) {
      id[x] += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  }

  return `${id[0]}-${id[1]}-${id[2]}`;
};

const calculateOrder = (
  products,
  orderLines,
  discountProdId,
  discountType,
  discountValue,
  taxValue,
  configInfo
) => {
  let newOrderLines = [...orderLines];
  let discountProduct = products.find((p) => p.id === discountProdId);
  let discountToApply;
  let isDiscounted = false;
  let taxToApply;
  isDiscounted = newOrderLines?.find((p) => p.id === discountProdId)
    ? true
    : false;
  let totalPrice = Number(
    newOrderLines
      .filter((oL) =>
        products.find((prod) => prod.id === oL.id && prod.id !== discountProdId)
      )
      ?.reduce(getTotal, 0)
      .toFixed(2)
  );
  if (discountType && discountValue) {
    if (discountType === 'percent') {
      discountToApply = Number(
        -1 * Number(totalPrice * Number(discountValue / 100))
      ).toFixed(2);
    }
    if (discountType === 'fixed') {
      discountToApply = Number(-1 * Number(discountValue));
    }
    if (isDiscounted) {
      newOrderLines = [
        ...newOrderLines.filter((oL) => oL.id !== discountProdId),
        {
          ...discountProduct,
          lst_price: Number(discountToApply),
          quantity: 1,
        },
      ];
    } else {
      newOrderLines = [
        ...newOrderLines,
        {
          ...discountProduct,
          lst_price: Number(discountToApply),
          quantity: 1,
        },
      ];
    }
    isDiscounted = true;
  }
  if (taxValue) {
    taxToApply = isDiscounted
      ? Number(
          Number(
            newOrderLines
              .filter((oL) => oL.id !== discountProdId.id)
              ?.reduce(getTotal, 0)
              .toFixed(2)
          ) * Number(taxValue / 100)
        ).toFixed(2)
      : Number(totalPrice * Number(taxValue / 100)).toFixed(2);
    newOrderLines = [
      ...newOrderLines.map((item) => {
        if (
          item.id === configInfo?.fbr_service_fee_product?.product_id ||
          (configInfo?.show_fbr_info && item?.saletax_exclude)
        ) {
          return {
            ...item,
            tax_value: 0,
          };
        }
        return {
          ...item,
          tax_value:
            item?.id !== discountProdId?.id
              ? Number(item.lst_price) -
                Number(
                  Number(item?.lst_price) * Number(100 / Number(100 + taxValue))
                )
              : 0,
        };
      }),
    ];
  }

  return newOrderLines;
};

const getDataFromIndexedDB = async (name, localforage) => {
  try {
    return await localforage.getItem(name);
  } catch (err) {
    message.error(`Fetch ${name} failed !`);
    console.error(err);
  }
};

const setDataInIndexedDB = async (name, data, localforage) => {
  try {
    return await localforage.setItem(name, data);
  } catch (err) {
    message.error(`Fetch ${name} failed !`);
    console.error(err);
  }
};

const getLimitedDataFromIndexedDB = async (
  name,
  index = MAX_RECORDS_FROM_INDEXEDDB,
  localforage
) => {
  try {
    const data = await localforage.getItem(name);

    return !data.length ? [] : data.filter((_, i) => i < index);
  } catch (err) {
    message.error(`Fetch ${name} failed !`);
    console.error(err);
  }
};

const getFontWeight = (type) => {
  if (typeof type === 'string') {
    type = type?.toLowerCase();
  }

  switch (type) {
    case 'regular':
      return { fontWeight: 'normal' };
    case 'bold':
      return { fontWeight: 'bold' };
    default:
      return { fontWeight: 'normal' };
  }
};

const getFontSize = (type) => {
  if (typeof type === 'string') {
    type = type?.toLowerCase();
  }

  switch (type) {
    case 'small':
      return { fontSize: 18 };
    case 'medium':
      return { fontSize: 24 };
    case 'large':
      return { fontSize: 30 };
    default:
      return { fontSize: 24 };
  }
};

const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  ret += hrs * 60 + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;

  return ret;
};

const getCardFontSizeForKds = (type) => {
  if (typeof type === 'string') {
    type = type?.toLowerCase();
  }

  switch (type) {
    case 'small':
      return 29;
    case 'medium':
      return 36;
    case 'large':
      return 45;
    default:
      return 32;
  }
};

const getDiscoundProduct = (products, config) => {
  return products?.find(
    (p) => p.id === config?.global_discount_default_item?.product_id
  );
};

export {
  generateId,
  calculateOrder,
  getDataFromIndexedDB,
  setDataInIndexedDB,
  getFontWeight,
  getFontSize,
  fancyTimeFormat,
  getCardFontSizeForKds,
  getLimitedDataFromIndexedDB,
  getDiscoundProduct,
};
