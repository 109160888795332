import { GET_INSTRUCTIONS } from "../types";

const initialState = {
  instructions: [],
};

const instructions = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTRUCTIONS:
      return { ...state, instructions: action.instructions };
    default:
      return state;
  }
};

export default instructions;
