import { FETCH_HOURLY_REPORT } from "../types";

const initialState = {
  report: [],
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOURLY_REPORT:
      return { ...state, report: action.report };
    default:
      return state;
  }
};

export default report;
