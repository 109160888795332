import { SET_CLOSING_REPORT_VIEW } from '../types';

const initialState = {
  status: false,
};

const isClosingReportViewSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLOSING_REPORT_VIEW:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default isClosingReportViewSelected;
