import { memo, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, message, Modal, Tooltip, Input } from 'antd';
import { DateTime } from 'luxon';
import localforage from 'localforage';

import { setOrder } from '../../store/actions/set/order';
import { getSavedOrders } from '../../store/actions/get/fetchSavedOrders';
import { saveForLater } from '../../store/actions/set/saveForLater';
import { setSaveForLaterOffline } from '../../store/actions/set/saveForLaterOffline';
import { setCustomersView } from '../../store/actions/set/customersView';
import { setPaymentView } from '../../store/actions/set/paymentView';
import { saveOpenOrder } from '../../store/actions/set/openOrder';
import { setCustomer } from '../../store/actions/set/customer';
import { setOilFloors } from '../../store/actions/set/oilFloors';
import { getCustomersPoints } from '../../store/actions/get/customersPoints';
import { setDisableCustomer } from '../../store/actions/set/disableCustomer';
import { setDiscardProductClicked } from '../../store/actions/set/discardProductClick';
import { SocketContext } from '../../Contexts/SocketContext';

import ErrorBoundary from '../ErrorBoundary';
import OrdersDialpad from '../OrdersDialpad';
import JobCardModal from '../JobCardModal';

import { DRIVETHRU, OIL_REST_HOTEL } from '../../constants';
import {
  generateId,
  calculateOrder,
  getDataFromIndexedDB,
  getDiscoundProduct,
} from '../../utils';

import './ordersactions.scss';
import { setSyncingInLiveMode } from '../../store/actions/set/syncingInLiveMode';

const getTotal = (prev, cur) => prev + parseFloat(cur.lst_price) * cur.quantity;

export const generateOrder = (
  order,
  configInfo,
  creation_date,
  customer,
  tableView
) => {
  const getPartnerId = () => {
    if (order.partner_id && customer?.id && order.partner_id !== customer?.id) {
      return customer.id;
    }

    return order.partner_id || customer?.id || false;
  };

  return {
    name: order.name || generateId(),
    partner_id: getPartnerId(),
    lines: order.lines?.map((oL) => [
      0, // Ignore it: Required by odoo
      0, // Ignore it: Required by odoo
      {
        discount: 0,
        pack_lot_ids: [], // Required By Odoo
        id: oL.id * Math.floor(Math.random(Math.random() + 5) * 25),
        price_subtotal: Number(oL.lst_price) * Number(oL.quantity),
        price_subtotal_incl: Number(oL.lst_price) * Number(oL.quantity),
        product_id: oL.id,
        qty: oL.quantity,
        price_unit: oL.lst_price,
        react_instructions_list:
          oL.react_instructions_list?.map((l) => l.id)?.toString() || false,
        tax_ids: [[6, false, []]], // Second Array -> First Index: taxId(product), Second Index: true(include), Third Index: Children Tax info (Format same as second array),
        note: oL.note || '',
        tax_value: oL?.tax_value,
        custom_discount: oL?.custom_discount || false,
        custom_discount_type: oL?.custom_discount_type || false,
        custom_discount_value: oL?.custom_discount_value || false,
        is_redeemed: oL?.is_redeemed || false,
      },
    ]),
    global_discount_type: order?.global_discount_type || false,
    global_discount_value: order?.global_discount_value || false,
    global_tax_value: order?.global_tax_value || false,
    pos_session_id: configInfo?.session_id,
    pricelist_id: configInfo?.pricelist_id,
    sequence_number: Math.floor(Math.random(Math.random() + 1) + 5 * 23),
    server_id: false, // Ignore it: Required by odoo
    fiscal_position_id: false, // Ignore it: Required by odoo
    creation_date,
    statement_ids: [], // Ignore it: Required by odoo
    uid: order.name || generateId(),
    amount_paid: 0, // Ignore it: Required by odoo
    amount_return: 0, // Ignore it: Required by odoo
    amount_tax: Number(order.amount_tax) || 0,
    amount_total: Number(order.lines.reduce(getTotal, 0).toFixed(2)),
    user_id: configInfo?.current_user_id,
    state: 'draft',
    order_status: 'open',
    is_available: true,
    order_type:
      order.orderType ||
      order.order_type ||
      (configInfo.order_types_list?.length &&
        configInfo.order_types_list[0]?.id) ||
      false,
    employee_id: false, // Ignore it: Required by odoo
    customer_count: 1, // Ignore it: Required by odoo
    select_employees: [], // Ignore it: Required by odoo
    employees: [], // Ignore it: Required by odoo
    order_prefix: configInfo.order_prefix,
    table: tableView?.table_name,
    table_id: tableView?.table_id,
    floor: tableView?.floor_name,
    floor_id: tableView?.floor_id,
    car_id: customer?.car_id,
    current_reading: order.current_reading,
    car_reading_per_day: order.car_reading_per_day,
    km_for_next_service: order.km_for_next_service,
    reading_on_next_service: order.reading_on_next_service,
    date_of_next_service: order.date_of_next_service,
    worker_id: order.workerId || false,
    device_id: order?.device_id || false,
    unique_order_key: order.unique_order_key || order.name || generateId(),
    order_time: order.order_time || configInfo.default_order_timer || false,
  };
};

const Index = memo(() => {
  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();
  const order = useSelector((state) => state.order?.order);
  // const products = useSelector((state) => state.products.products);
  const customer = useSelector((state) => state.customer.customer);
  const customers = useSelector((state) => state.customers.customers);
  const configInfo = useSelector((state) => state.configInfo.configInfo);
  const orderType = useSelector((state) => state.orderType.orderType);
  const user = useSelector((state) => state.login.user);
  const selectedPos = useSelector((state) => state.selectedPos.selectedPos);
  const oilFloors = useSelector((state) => state.oilFloors?.oilFloors);
  const floors = useSelector((state) => state.floors?.floors);
  const tableView = useSelector((state) => state.tableView?.tableView);
  const isCustomerDisabled = useSelector(
    (state) => state.disableCustomer.status
  );
  const isSyncingInProgressInLiveMode = useSelector(
    (state) => state.isSyncingInProgressInLiveMode.status
  );

  const debounce = useSelector((state) => state.debounceTerm.debounceTerm);
  const cancelToken = useSelector((state) => state.cancelToken.cancelToken);
  const [display, setDisplay] = useState('');
  const [toggleValue, setToggleValue] = useState('percent');
  const [actionsModal, setActionsModal] = useState({
    status: false,
    action: '',
  });
  const [jobCardModal, setJobCardModal] = useState(false);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [isSaveForLaterButtonDisabled, setIsSaveForLaterButtonDisabled] =
    useState(false);
  const [isOrderTimerModalOpen, setIsOrderTimerModalOpen] = useState(false);
  const [orderTimerValue, setOrderTimerValue] = useState(
    configInfo?.default_order_timer
  );

  const handleSaveForLaterOperations = async (isOkPressed = false) => {
    if (selectedPos?.pos_type === DRIVETHRU) {
      dispatch(setSyncingInLiveMode(true));
    }
    setIsSaveForLaterButtonDisabled(true);

    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      let selectedTableOrder;
      let selectedTableCustomer;
      (oilFloors?.length ? oilFloors : floors)?.find((f) => {
        if (f.id === tableView.floor_id) {
          selectedTableOrder = f.tables.find(
            (t) => t.id === tableView.table_id
          )?.order;
          const workerId = f.tables.find(
            (t) => t.id === tableView.table_id
          )?.workerId;
          selectedTableOrder = { ...selectedTableOrder, workerId };
          selectedTableCustomer = f.tables.find(
            (t) => t.id === tableView.table_id
          )?.customer;
        }
      });

      if (selectedTableOrder?.lines?.length) {
        const creationDate = DateTime.now().toUTC();
        const data = {
          data: generateOrder(
            { ...selectedTableOrder, orderType, device_id: user.device_id },
            configInfo,
            creationDate,
            selectedTableCustomer,
            tableView
          ),
          ...user,
        };

        if (window.navigator.onLine) {
          const response = await saveForLater(data);
          if (response === 'success') {
            const newFloors = (oilFloors?.length ? oilFloors : floors)?.map(
              (f) => {
                if (f.id === tableView.floor_id) {
                  f.tables?.forEach((t) => {
                    if (t.id === tableView.table_id) {
                      if (t.order) {
                        t.order = null;
                      }
                      if (t.customer) {
                        t.customer = null;
                      }
                      if (t.workerId) {
                        t.workerId = null;
                      }
                    }
                  });
                }

                return f;
              }
            );

            dispatch(setOilFloors([...newFloors]));
            let requiredData = {
              ...user,
              sessionId: configInfo?.session_id,
              PosType: selectedPos?.pos_type,
            };
            if (selectedPos?.pos_type === OIL_REST_HOTEL) {
              requiredData = {
                ...requiredData,
                floor_id: tableView.floor_id,
                table_id: tableView.table_id,
              };
            }
            socket.emit('update_kds_screen', {
              ...user,
              sessionId: configInfo?.session_id,
              bump_screen_mode: configInfo?.bump_screen_mode,
              // configInfo,
            });
            // dispatch(getSavedOrders(requiredData));
            socket.emit('update_saved_orders_count', {
              ...requiredData,
            });
          }
        } else {
          const newFloors = (oilFloors?.length ? oilFloors : floors)?.map(
            (f) => {
              if (f.id === tableView.floor_id) {
                f.tables?.forEach((t) => {
                  if (t.id === tableView.table_id) {
                    if (t.id === tableView.table_id) {
                      if (t.orders?.length) {
                        t.orders = [
                          ...t.orders,
                          {
                            selectedTableOrder,
                            selectedTableCustomer,
                          },
                        ];
                      } else {
                        t.orders = [
                          {
                            selectedTableOrder,
                            selectedTableCustomer,
                          },
                        ];
                      }
                    }
                    if (t.order) {
                      t.order = null;
                    }
                    if (t.customer) {
                      t.customer = null;
                    }
                    if (t.workerId) {
                      t.workerId = null;
                    }
                  }
                });
              }

              return f;
            }
          );

          dispatch(setOilFloors([...newFloors]));
        }
      }
    } else if (order.lines?.length) {
      const creationDate = DateTime.now().toUTC();
      const data = {
        data: generateOrder(
          {
            ...order,
            orderType,
            order_time: isOkPressed
              ? orderTimerValue
              : configInfo?.default_order_timer,
            device_id: user.device_id,
          },
          configInfo,
          creationDate,
          customer
        ),
        ...user,
      };

      if (window.navigator.onLine) {
        //TODO:
        debounce.cancel();
        cancelToken?.cancel();
        const response = await saveForLater(data);
        if (response === 'success') {
          dispatch(
            setOrder({
              name: generateId(),
              lines: [],
              salesmanId: undefined,
              orderType: undefined,
              payment: undefined,
              workerId: configInfo?.worker_fix ? order.workerId : undefined,
            })
          );
          // dispatch(
          //   getSavedOrders({
          //     ...user,
          //     sessionId: configInfo?.session_id,
          //     PosType: selectedPos?.pos_type,
          //   })
          // );
          socket.emit('update_saved_orders_count', {
            ...user,
            sessionId: configInfo?.session_id,
            PosType: selectedPos?.pos_type,
            table_id: tableView?.table_id,
            floor_id: tableView?.floor_id,

          });
          socket.emit('update_kds_screen', {
            ...user,
            sessionId: configInfo?.session_id,
            bump_screen_mode: configInfo?.bump_screen_mode,
            // configInfo,
          });
          debounce.cancel();
          dispatch(setSyncingInLiveMode(false));
          dispatch(setCustomer(undefined));
          dispatch(setDisableCustomer(false));
        } else {
          dispatch(setSyncingInLiveMode(false));
          dispatch(setCustomer(undefined));
          dispatch(setDisableCustomer(false));
        }
      } else {
        dispatch(
          setSaveForLaterOffline({
            ...order,
            partner_id: customer?.id,
            orderType,
          })
        );
        dispatch(
          setOrder({
            name: generateId(),
            lines: [],
            salesmanId: undefined,
            orderType: undefined,
            payment: undefined,
            workerId: configInfo?.worker_fix ? order.workerId : undefined,
          })
        );
        debounce.cancel();
        dispatch(setSyncingInLiveMode(false));
        dispatch(setCustomer(undefined));
        dispatch(setDisableCustomer(false));
      }
    }
    setIsSaveForLaterButtonDisabled(false);
  };

  const showModal = (e) => {
    setActionsModal({
      status: true,
      action: e.target.innerText === 'Update' ? 'Qty' : e.target.innerText,
    });
  };

  const handleOk = async (closeModal = true) => {
    const allProducts = await getDataFromIndexedDB('products', localforage);

    let discountProd = getDiscoundProduct(allProducts, configInfo);
    if (actionsModal.action === 'Qty' && Number(display)) {
      const creationDate = DateTime.now().toUTC();
      let updatedOrder = {
        ...order,
        lines: order.lines.map((line) =>
          line.isSelected
            ? {
              ...line,
              quantity: Number(display).toFixed(2),
            }
            : line
        ),
      };
      const data = {
        data: generateOrder(
          { ...updatedOrder, orderType, order_status: 'live' },
          configInfo,
          creationDate,
          customer
        ),
        ...user,
      };

      if (
        window.navigator.onLine &&
        configInfo?.live_order_sync &&
        selectedPos?.pos_type === DRIVETHRU
      ) {
        const orderName = await saveOpenOrder(data);
        if (orderName) {
          if (
            !(
              configInfo?.order_prefix &&
              typeof updatedOrder?.name !== 'boolean' &&
              updatedOrder?.name?.includes(configInfo.order_prefix)
            )
          ) {
            updatedOrder = { ...updatedOrder, name: orderName };
          }
        }
      }

      if (selectedPos?.pos_type === OIL_REST_HOTEL) {
        const newFloors = (oilFloors?.length ? oilFloors : floors)?.map((f) => {
          if (f.id === tableView.floor_id) {
            f.tables?.forEach((t) => {
              if (t.id === tableView.table_id && t.order?.lines.length) {
                t.order = {
                  ...t.order,
                  lines: t.order?.lines.map((line) =>
                    line.isSelected
                      ? {
                        ...line,
                        quantity: Number(display).toFixed(2),
                      }
                      : line
                  ),
                };
                if (t.order) {
                  t.order = {
                    ...t.order,
                    amount_tax: t.order.global_tax_value
                      ? Number(
                        Number(
                          Number(
                            t.order.lines
                              .filter((oL) =>
                                allProducts.find(
                                  (prod) =>
                                    prod.id === oL.id &&
                                    prod.id !== discountProd?.id &&
                                    prod.id !==
                                    configInfo?.fbr_service_fee_product
                                      ?.product_id
                                )
                              )
                              ?.reduce(getTotal, 0)
                              .toFixed(2)
                          )
                        ) * Number(Number(t.order.global_tax_value / 100))
                      ).toFixed(2)
                      : 0,
                    lines: calculateOrder(
                      allProducts,
                      t.order.lines,
                      configInfo?.global_discount_default_item?.product_id,
                      t.order.global_discount_type
                        ? t.order.global_discount_type
                        : false,
                      t.order.global_discount_value
                        ? t.order.global_discount_value
                        : false,
                      t.order.global_tax_value
                        ? t.order.global_tax_value
                        : false,
                      configInfo
                    ),
                  };
                }
              }
            });
          }

          return f;
        });

        dispatch(setOilFloors([...newFloors]));
      } else {
        const newUpdatedOrder = {
          ...updatedOrder,
          amount_tax: updatedOrder.global_tax_value
            ? Number(
              Number(
                Number(
                  updatedOrder.lines
                    .filter((oL) =>
                      allProducts.find(
                        (prod) =>
                          prod.id === oL.id &&
                          prod.id !== discountProd?.id &&
                          prod.id !==
                          configInfo?.fbr_service_fee_product?.product_id
                      )
                    )
                    ?.reduce(getTotal, 0)
                    .toFixed(2)
                )
              ) * Number(Number(updatedOrder.global_tax_value / 100))
            ).toFixed(2)
            : 0,
          lines: calculateOrder(
            allProducts,
            updatedOrder.lines,
            configInfo?.global_discount_default_item?.product_id,
            updatedOrder.global_discount_type
              ? updatedOrder.global_discount_type
              : false,
            updatedOrder.global_discount_value
              ? updatedOrder.global_discount_value
              : false,
            updatedOrder.global_tax_value
              ? updatedOrder.global_tax_value
              : false,
            configInfo
          ),
        };
        dispatch(
          setOrder({
            ...newUpdatedOrder,
          })
        );
      }
    }

    if (
      (actionsModal.action === 'Price' ||
        actionsModal.action === 'Price Update') &&
      display
    ) {
      let customDiscount;

      if (selectedPos?.pos_type === OIL_REST_HOTEL) {
        let selectedTableOrder;
        (oilFloors?.length ? oilFloors : floors)?.find((f) => {
          if (f.id === tableView.floor_id) {
            selectedTableOrder = f.tables.find(
              (t) => t.id === tableView.table_id
            )?.order;
          }
        });
        customDiscount = selectedTableOrder?.lines?.find(
          (line) => line.isSelected
        )?.custom_discount;
      } else {
        customDiscount = order.lines?.find(
          (line) => line.isSelected
        )?.custom_discount;
      }

      const discount = Number(customDiscount || 0) / 100;
      const newListPrice = Number(display) - Number(display) * discount;

      if (selectedPos?.pos_type === OIL_REST_HOTEL) {
        const newFloors = (oilFloors?.length ? oilFloors : floors)?.map((f) => {
          if (f.id === tableView.floor_id) {
            f.tables?.forEach((t) => {
              if (t.id === tableView.table_id && t.order?.lines.length) {
                t.order = {
                  ...t.order,
                  lines: t.order?.lines.map((line) =>
                    line.isSelected
                      ? {
                        ...line,
                        lst_price: line?.custom_discount
                          ? Number(newListPrice)
                          : Number(display),
                      }
                      : line
                  ),
                };
                if (t.order) {
                  t.order = {
                    ...t.order,
                    amount_tax: t.order.global_tax_value
                      ? Number(
                        Number(
                          Number(
                            t.order.lines
                              .filter((oL) =>
                                allProducts.find(
                                  (prod) =>
                                    prod.id === oL.id &&
                                    prod.id !== discountProd?.id &&
                                    prod.id !==
                                    configInfo?.fbr_service_fee_product
                                      ?.product_id
                                )
                              )
                              ?.reduce(getTotal, 0)
                              .toFixed(2)
                          )
                        ) * Number(Number(t.order.global_tax_value / 100))
                      ).toFixed(2)
                      : 0,
                    lines: calculateOrder(
                      allProducts,
                      t.order.lines,
                      configInfo?.global_discount_default_item?.product_id,
                      t.order.global_discount_type
                        ? t.order.global_discount_type
                        : false,
                      t.order.global_discount_value
                        ? t.order.global_discount_value
                        : false,
                      t.order.global_tax_value
                        ? t.order.global_tax_value
                        : false,
                      configInfo
                    ),
                  };
                }
              }
            });
          }

          return f;
        });

        dispatch(setOilFloors([...newFloors]));
      } else {
        const updatedOrder = {
          ...order,
          lines: order.lines.map((line) =>
            line.isSelected
              ? {
                ...line,
                lst_price: line?.custom_discount
                  ? Number(newListPrice)
                  : Number(display),
              }
              : line
          ),
        };
        const newUpdatedOrder = {
          ...updatedOrder,
          amount_tax: updatedOrder.global_tax_value
            ? Number(
              Number(
                Number(
                  updatedOrder.lines
                    .filter((oL) =>
                      allProducts.find(
                        (prod) =>
                          prod.id === oL.id &&
                          prod.id !== discountProd?.id &&
                          prod.id !==
                          configInfo?.fbr_service_fee_product?.product_id
                      )
                    )
                    ?.reduce(getTotal, 0)
                    .toFixed(2)
                )
              ) * Number(Number(updatedOrder.global_tax_value / 100))
            ).toFixed(2)
            : 0,
          lines: calculateOrder(
            allProducts,
            updatedOrder.lines,
            configInfo?.global_discount_default_item?.product_id,
            updatedOrder.global_discount_type
              ? updatedOrder.global_discount_type
              : false,
            updatedOrder.global_discount_value
              ? updatedOrder.global_discount_value
              : false,
            updatedOrder.global_tax_value
              ? updatedOrder.global_tax_value
              : false,
            configInfo
          ),
        };
        dispatch(
          setOrder({
            ...newUpdatedOrder,
          })
        );
      }
    }

    if (actionsModal.action === 'Disc.' && display) {
      if (toggleValue === 'percent' && (display > 100 || display < 0)) {
        message.error('Discount value must be between 0 and 100');
        return;
      }
      let discount;
      if (toggleValue === 'percent') {
        discount = Number(display) / 100;
      } else {
        discount = Number(display);
      }
      let listPrice;
      if (selectedPos?.pos_type === OIL_REST_HOTEL) {
        let selectedTableOrder;
        (oilFloors?.length ? oilFloors : floors)?.find((f) => {
          if (f.id === tableView.floor_id) {
            selectedTableOrder = f.tables.find(
              (t) => t.id === tableView.table_id
            )?.order;
          }
        });
        listPrice = allProducts.find(
          (prod) =>
            prod.id ===
            selectedTableOrder?.lines?.find((line) => line.isSelected)?.id
        ).lst_price;
        if (toggleValue === 'fixed' && (display > listPrice || display < 0)) {
          message.error("Discount value can't be less than original value");
          return;
        }
      } else {
        listPrice = allProducts.find(
          (prod) => prod.id === order.lines?.find((line) => line.isSelected)?.id
        )?.lst_price;
        if (toggleValue === 'fixed' && (display > listPrice || display < 0)) {
          message.error("Discount value can't be less than original value");
          return;
        }
      }
      let newListPrice;
      if (toggleValue === 'percent') {
        newListPrice = listPrice - listPrice * discount;
      } else {
        newListPrice = listPrice - discount;
      }

      if (selectedPos?.pos_type === OIL_REST_HOTEL) {
        const newFloors = (oilFloors?.length ? oilFloors : floors)?.map((f) => {
          if (f.id === tableView.floor_id) {
            f.tables?.forEach((t) => {
              if (t.id === tableView.table_id && t.order?.lines.length) {
                t.order = {
                  ...t.order,
                  lines: t.order?.lines?.map((line) =>
                    line?.isSelected
                      ? {
                        ...line,
                        lst_price: Number(newListPrice),
                        custom_discount_type: toggleValue,
                        custom_discount: Number(display).toFixed(2),
                        custom_discount_value:
                          toggleValue === 'percent'
                            ? Number(listPrice * discount).toFixed(2)
                            : Number(discount).toFixed(2),
                      }
                      : { ...line }
                  ),
                };
                if (t.order) {
                  t.order = {
                    ...t.order,
                    amount_tax: t.order.global_tax_value
                      ? Number(
                        Number(
                          Number(
                            t.order.lines
                              .filter((oL) =>
                                allProducts.find(
                                  (prod) =>
                                    prod.id === oL.id &&
                                    prod.id !== discountProd?.id &&
                                    prod.id !==
                                    configInfo?.fbr_service_fee_product
                                      ?.product_id
                                )
                              )
                              ?.reduce(getTotal, 0)
                              .toFixed(2)
                          )
                        ) * Number(Number(t.order.global_tax_value / 100))
                      ).toFixed(2)
                      : 0,
                    lines: calculateOrder(
                      allProducts,
                      t.order.lines,
                      configInfo?.global_discount_default_item?.product_id,
                      t.order.global_discount_type
                        ? t.order.global_discount_type
                        : false,
                      t.order.global_discount_value
                        ? t.order.global_discount_value
                        : false,
                      t.order.global_tax_value
                        ? t.order.global_tax_value
                        : false,
                      configInfo
                    ),
                  };
                }
              }
            });
          }

          return f;
        });

        dispatch(setOilFloors([...newFloors]));
      } else {
        const updatedOrder = {
          ...order,
          lines: order.lines.map((line) =>
            line.isSelected
              ? {
                ...line,
                lst_price: Number(display) > 100 ? 0 : Number(newListPrice),
                custom_discount_type: toggleValue,
                custom_discount: Number(display).toFixed(2),
                custom_discount_value:
                  toggleValue === 'percent'
                    ? Number(listPrice * discount).toFixed(2)
                    : Number(discount).toFixed(2),
              }
              : line
          ),
        };
        const newUpdatedOrder = {
          ...updatedOrder,
          amount_tax: updatedOrder.global_tax_value
            ? Number(
              Number(
                Number(
                  updatedOrder.lines
                    .filter((oL) =>
                      allProducts.find(
                        (prod) =>
                          prod.id === oL.id &&
                          prod.id !== discountProd?.id &&
                          prod.id !==
                          configInfo?.fbr_service_fee_product?.product_id
                      )
                    )
                    ?.reduce(getTotal, 0)
                    .toFixed(2)
                )
              ) * Number(Number(updatedOrder.global_tax_value / 100))
            ).toFixed(2)
            : 0,
          lines: calculateOrder(
            allProducts,
            updatedOrder.lines,
            configInfo?.global_discount_default_item?.product_id,
            updatedOrder.global_discount_type
              ? updatedOrder.global_discount_type
              : false,
            updatedOrder.global_discount_value
              ? updatedOrder.global_discount_value
              : false,
            updatedOrder.global_tax_value
              ? updatedOrder.global_tax_value
              : false,
            configInfo
          ),
        };
        dispatch(
          setOrder({
            ...newUpdatedOrder,
          })
        );
      }
    }

    setDisplay('');
    if (
      configInfo?.live_order_sync &&
      window.navigator.onLine &&
      selectedPos?.pos_type === DRIVETHRU
    ) {
      setTimeout(() => {
        dispatch(setDiscardProductClicked(true));
      }, 500);
    }
    if (closeModal) {
      setActionsModal({ status: false, action: '' });
    }
  };

  const handleSaveForLater = async () => {
    // const saveForLaterOperations = () => {
    if (selectedPos?.pos_type === DRIVETHRU && configInfo?.show_order_timer) {
      setIsOrderTimerModalOpen(true);
    } else {
      handleSaveForLaterOperations();
    }
    // };
    // if (isSyncingInProgressInLiveMode && configInfo?.live_order_sync) {
    //   setTimeout(() => {
    //     saveForLaterOperations();
    //   }, 1000);
    // } else {
    // saveForLaterOperations();
    // }
  };

  const getDisabledState = () => {
    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      let selectedTableOrder;
      (oilFloors?.length ? oilFloors : floors)?.find((f) => {
        if (f.id === tableView.floor_id) {
          selectedTableOrder = f.tables.find(
            (t) => t.id === tableView.table_id
          )?.order;
        }
      });

      return selectedTableOrder?.lines?.length ? false : true;
    }

    return order?.lines?.length ? false : true;
  };

  const getCustomerButtonText = () => {
    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      let selectedTableCustomer;
      (oilFloors?.length ? oilFloors : floors)?.find((f) => {
        if (f.id === tableView.floor_id) {
          selectedTableCustomer = f.tables.find(
            (t) => t.id === tableView.table_id
          )?.customer;
        }
      });

      return selectedTableCustomer ? (
        <Tooltip
          title={`${selectedTableCustomer.name} ${selectedTableCustomer.car?.vehicle_no
            ? `(${selectedTableCustomer.car?.vehicle_no})`
            : ''
            }`}
        >
          {selectedTableCustomer.name}
          <span className='points'>
            {selectedTableCustomer.car?.vehicle_no
              ? `(${selectedTableCustomer.car?.vehicle_no})`
              : ''}
          </span>
        </Tooltip>
      ) : (
        'Job Card'
      );
    } else {
      return customer ? (
        <Tooltip
          title={`${customer.name} ${customer.points_earned ? `(${customer.points_earned})` : ''
            }`}
        >
          {customer.name}
          <span className='points'>
            {customer.points_earned ? `(${customer.points_earned})` : ''}
          </span>
        </Tooltip>
      ) : (
        'Add Customer'
      );
    }
  };

  const showJobCardModal = () => {
    setJobCardModal(true);
  };

  const hideJobCardModal = () => {
    setJobCardModal(false);
  };

  const handleOrderTimeChange = async (isOkPressed) => {
    setIsOrderTimerModalOpen(!isOrderTimerModalOpen);
    handleSaveForLaterOperations(isOkPressed);
  };

  return (
    <ErrorBoundary>
      <div className='orders-actions'>
        <div
          className='actions-wrap'
          style={
            configInfo.enlarge_action_button
              ? {
                gridTemplateColumns:
                  'calc(50% - 0.25rem) calc(50% + 0.25rem)',
              }
              : {}
          }
        >
          <Button
            className={`add-customer ${configInfo.enlarge_action_button ? ' add-customer-large' : ''
              }`}
            disabled={isCustomerDisabled || !customers?.length}
            onClick={() => {
              if (selectedPos?.pos_type === DRIVETHRU) {
                socket.emit('update_customer_points', {
                  ...user,
                  sessionId: configInfo?.session_id,
                });
                // dispatch(getCustomersPoints(user));
              }
              dispatch(setCustomersView(true));
              // if (selectedPos?.pos_type === OIL_REST_HOTEL) {
              //   showJobCardModal();
              // }
            }}
          >
            {getCustomerButtonText()}
          </Button>
          <div
            className={`${selectedPos?.pos_type === OIL_REST_HOTEL ? 'oil-actions' : ''
              }`}
          >
            <Button
              type='primary'
              onClick={showModal}
              disabled={getDisabledState()}
              className={`update-action-button${configInfo.enlarge_action_button
                ? ' price-update-color-ful'
                : ''
                }`}
            >
              {configInfo.enlarge_action_button ? 'Price Update' : 'Update'}
            </Button>
          </div>
        </div>
        <div
          className='payment-wrap'
          style={
            configInfo.enlarge_action_button
              ? {
                gridTemplateColumns:
                  'calc(50% - 0.25rem) calc(50% - 0.25rem)',
              }
              : {}
          }
        >
          <Button
            className={`${configInfo.enlarge_action_button ? 'payment-large' : 'payment'
              }`}
            type='primary'
            disabled={getDisabledState()}
            onClick={() => {
              let isWorkerSelected = false;
              if (selectedPos?.pos_type === OIL_REST_HOTEL) {
                (oilFloors?.length ? oilFloors : floors)?.map((f) => {
                  if (f.id === tableView.floor_id) {
                    f.tables?.forEach((t) => {
                      if (t.id === tableView.table_id) {
                        if (t.workerId) {
                          isWorkerSelected = true;
                        }
                      }
                    });
                  }
                });
              } else {
                if (order?.workerId) {
                  isWorkerSelected = true;
                }
              }
              isWorkerSelected
                ? dispatch(setPaymentView(true))
                : message.warning('Please select worker!', 1);
            }}
          >
            Payment
          </Button>
          <Button
            className={`save-for-later ${configInfo.enlarge_action_button ? ' save-for-later-large' : ''
              }`}
            disabled={getDisabledState() || isSaveForLaterButtonDisabled}
            onClick={() => {
              let isWorkerSelected = false;
              if (selectedPos?.pos_type === OIL_REST_HOTEL) {
                (oilFloors?.length ? oilFloors : floors)?.map((f) => {
                  if (f.id === tableView.floor_id) {
                    f.tables?.forEach((t) => {
                      if (t.id === tableView.table_id) {
                        if (t.workerId) {
                          isWorkerSelected = true;
                        }
                      }
                    });
                  }
                });
              } else {
                if (order?.workerId) {
                  isWorkerSelected = true;
                }
              }
              isWorkerSelected
                ? handleSaveForLater()
                : message.warning('Please select worker!', 1);
            }}
          >
            {configInfo.enlarge_action_button ? 'Save' : 'Save For Later'}
          </Button>
        </div>
      </div>
      <Modal
        visible={actionsModal.status}
        onCancel={() => {
          setisAuthenticated(false);
          setActionsModal({ status: false, action: '' });
        }}
        className='orders-dialpad-modal'
        footer={null}
      >
        <OrdersDialpad
          action={actionsModal.action}
          display={display}
          setDisplay={setDisplay}
          handleOk={handleOk}
          setActionsModal={setActionsModal}
          actionsModal={actionsModal}
          discType={toggleValue}
          setDiscType={setToggleValue}
          isAuthenticated={isAuthenticated}
          setisAuthenticated={setisAuthenticated}
        />
      </Modal>
      <Modal
        visible={jobCardModal}
        onCancel={hideJobCardModal}
        className='job-card-modal'
        footer={
          <Button
            type='primary'
            size='large'
            onClick={() => dispatch(setCustomersView(true))}
          >
            View Customers Page
          </Button>
        }
      >
        <JobCardModal hideModalAction={hideJobCardModal} />
      </Modal>
      <Modal
        title='Please Set Order Time In Minutes'
        visible={isOrderTimerModalOpen}
        onCancel={() => handleOrderTimeChange()}
        onOk={() => handleOrderTimeChange(true)}
      >
        <Input
          type='number'
          size='large'
          placeholder='Please Add Order Time In Minutes'
          value={orderTimerValue}
          onChange={(e) => setOrderTimerValue(Number(e.target.value))}
          style={{ width: '100%' }}
        />
      </Modal>
    </ErrorBoundary>
  );
});

export default Index;
