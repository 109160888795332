import { message } from 'antd';

import { callApi } from '../../service';
import { GET_CARS, SET_CARS } from '../../types';

const getCars = (data, cars) => async (dispatch) => {
  if (cars?.length) {
    dispatch({ type: SET_CARS, cars });
    return;
  }

  let concatCars = [];

  const getCars = async (last_item_id, dataT) => {
    const requestdata = {
      api_type: 'cars',
      db: data.database,
      email: data.email,
      password: data.password,
      url: data.url,
      req_arg: {
        last_item_id: last_item_id,
      },
    };

    const tempResp = await callApi(requestdata);

    if (tempResp?.data?.error) {
      return message.error(
        `${tempResp.data.error.message}, Fetch Cars Failed!`,
        1
      );
    }
    if (tempResp?.data?.result?.data && !tempResp?.data?.result?.data.length) {
      return message.info('Oops, Cars Not Found!', 1);
    }

    concatCars = [...concatCars, ...tempResp?.data?.result?.data];

    if (
      typeof tempResp?.data?.result?.last_item_id !== Boolean &&
      tempResp?.data?.result?.last_item_id
    ) {
      getCars(tempResp?.data?.result?.last_item_id, dataT);
    } else {
      dispatch({ type: GET_CARS, cars: [...concatCars] });
    }
  };
  await getCars(0, data);
};

export { getCars };
