import { GET_INITIAL_POS_INFO } from "../types";

const initialState = {
  initialPosInfo: [],
};

const initialPosInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_INITIAL_POS_INFO:
      return { ...state, initialPosInfo: action.initialPosInfo };
    default:
      return state;
  }
};

export default initialPosInfo;
