export const USER_LOGIN = 'USER_LOGIN';

export const GET_CONFIG_DETAILS = 'GET_CONFIG_DETAILS';
export const GET_CONFIG_INFO = 'GET_CONFIG_INFO';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_FLOORS = 'GET_FLOORS';
export const GET_INVOICE_SEARCH = 'GET_INVOICE_SEARCH';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_INSTRUCTIONS = 'GET_INSTRUCTIONS';
export const GET_INSTRUCTION_INFO = 'GET_INSTRUCTION_INFO';
export const GET_SALESMEN = 'GET_SALESMEN';
export const GET_SALESMEN_INFO = 'GET_SALESMEN_INFO';
export const GET_INITIAL_POS_INFO = 'GET_INITIAL_POS_INFO';
export const FETCH_SAVED_ORDERS = 'FETCH_SAVED_ORDERS';
export const GET_CUSTOMERS_PREVIOUS_ORDERS = 'GET_CUSTOMERS_PREVIOUS_ORDERS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_KDS_ORDERS = 'GET_KDS_ORDERS';
export const GET_CAR_BRANDS = 'GET_CAR_BRANDS';
export const GET_CARS = 'GET_CARS';
export const GET_TAX_CLASSES = 'GET_TAX_CLASSES';
export const FETCH_CAR_READINGS = 'FETCH_CAR_READINGS';
export const FETCH_SMS_LOG = 'FETCH_SMS_LOG';
export const FETCH_HOURLY_REPORT = 'FETCH_HOURLY_REPORT';
export const GET_INVENTORY = 'GET_INVENTORY';
export const FETCH_CLOSING_REPORT = 'FETCH_CLOSING_REPORT';
export const GET_CUSTOMERS_POINTS = 'GET_CUSTOMERS_POINTS';
export const GET_CLOSING_REPORT_FILTERS = 'GET_CLOSING_REPORT_FILTERS';
export const GET_CUSTOMERS_LAST_ORDER = 'GET_CUSTOMERS_LAST_ORDER';

export const SET_CUSTOMERS_LAST_ORDER = 'SET_CUSTOMERS_LAST_ORDER';
export const SET_SELECTED_POS = 'SET_SELECTED_POS';
export const SET_PRODUCTS_SEARCH_VALUE = 'SET_PRODUCTS_SEARCH_VALUE';
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
export const SET_SALESMAN = 'SET_SALESMAN';
export const SET_ORDER = 'SET_ORDER';
export const SET_SAVE_FOR_LATER = 'SET_SAVE_FOR_LATER';
export const SET_SAVE_FOR_LATER_OFFLINE = 'SET_SAVE_FOR_LATER_OFFLINE';
export const SET_TODAYS_ORDERS = 'SET_TODAYS_ORDERS';
export const FETCH_TODAYS_ORDERS = 'FETCH_TODAYS_ORDERS';
export const SET_CUSTOMERS_VIEW = 'SET_CUSTOMERS_VIEW';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMERS_PREVIOUS_ORDERS_VIEW =
  'SET_CUSTOMERS_PREVIOUS_ORDERS_VIEW';
export const SET_PAYMENT_VIEW = 'SET_PAYMENT_VIEW';
export const SET_INVOICE_VIEW = 'SET_INVOICE_VIEW';
export const SET_PAYMENT_LIST = 'SET_PAYMENT_LIST';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const SET_TABLE_VIEW = 'SET_TABLE_VIEW';
export const SET_FLOORS = 'SET_FLOORS';
export const SET_DUPLICATE_BILL_VIEW = 'SET_DUPLICATE_BILL_VIEW';
export const SET_HOURLY_REPORT_VIEW = 'SET_HOURLY_REPORT_VIEW';
export const SET_CARS = 'SET_CARS';
export const SET_KEY_DOWN_EVENT = 'SET_KEY_DOWN_EVENT';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_KDS_ORDERS = 'SET_KDS_ORDERS';
export const SET_CLOSING_REPORT_VIEW = 'SET_CLOSING_REPORT_VIEW';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_CUSTOMERS_PREVIOUS_ORDERS = 'SET_CUSTOMERS_PREVIOUS_ORDERS';
export const SET_CUSTOMERS_SEARCH = 'SET_CUSTOMERS_SEARCH';
export const SET_DISABLE_CUSTOMER = 'SET_DISABLE_CUSTOMER';
export const SET_DISCARD_PRODUCT_CLICK = 'SET_DISCARD_PRODUCT_CLICK';
export const SET_SYNCING_IN_LIVE_MODE = 'SET_SYNCING_IN_LIVE_MODE';
export const SET_SELECTED_ORDER_LINE = 'SET_SELECTED_ORDER_LINE';
export const SET_SHOULD_RESUME_SYNC = 'SET_SHOULD_RESUME_SYNC';
export const REDUCE_PRODUCT_QTY = 'REDUCE_PRODUCT_QTY';

export const SET_DEBOUNCE_TERM = 'SET_DEBOUNCE_TERM';
export const SET_CANCEL_TOKEN = 'SET_CANCEL_TOKEN';

export const RESET_STORE = 'RESET_STORE';
