import { FETCH_TODAYS_ORDERS } from "../types";

const initialState = {
  todaysOrders: [],
};

const todaysOrders = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TODAYS_ORDERS:
      return { ...state, todaysOrders: action.todaysOrders };
    default:
      return state;
  }
};

export default todaysOrders;
