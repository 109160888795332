import { GET_INVENTORY, REDUCE_PRODUCT_QTY } from '../types';

const initialState = {
  inventory: [],
};

const inventory = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVENTORY:
      return { ...state, inventory: action.inventory };
    case REDUCE_PRODUCT_QTY:
      const dummyInvent = { ...state?.inventory[0] };
      action.ids?.forEach((prod) => {
        if (Object.keys(state?.inventory[0]).includes(prod?.id?.toString())) {
          dummyInvent[prod?.id] = dummyInvent[prod?.id] - prod?.quantity;
        }
      });
      return {
        ...state,
        inventory: [{ ...dummyInvent }],
      };
    default:
      return state;
  }
};

export default inventory;
