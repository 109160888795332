import { GET_FLOORS } from "../types";

const initialState = {
  floors: [],
};

const floors = (state = initialState, action) => {
  switch (action.type) {
    case GET_FLOORS:
      return { ...state, floors: action.floors };
    default:
      return state;
  }
};

export default floors;
