import { SET_DUPLICATE_BILL_VIEW } from '../types';

const initialState = {
  status: false,
};

const duplicateBillView = (state = initialState, action) => {
  switch (action.type) {
    case SET_DUPLICATE_BILL_VIEW:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default duplicateBillView;
