import { SET_SEARCH_TYPE } from '../types';

const initialState = {
  searchType: 'vehicle',
};

const searchType = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.searchType,
      };
    default:
      return state;
  }
};

export default searchType;
