import { SET_CUSTOMERS_SEARCH } from '../types';

const initialState = {
  customersSearch: '',
};

const customersSearch = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS_SEARCH:
      return { ...state, customersSearch: action.customersSearch };
    default:
      return state;
  }
};

export default customersSearch;
