import { message } from "antd";

import { callApi } from "../../service";

const discardOrder = async (data) => {
  const response = await callApi({
    api_type: "discard_order",
    db: data.database,
    email: data.email,
    password: data.password,
    url: data.url,
    req_arg: {
      order_name: data.orderName,
    },
  });

  if (response?.data?.error) {
    message.error(`${response.data.error.message}, Discard Order Failed!`, 1);
    return;
  }

  return "success";
};

export { discardOrder };
