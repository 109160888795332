import { Button, Input, message, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Clear } from '../../assets/backspace.svg';
import { DRIVETHRU, OIL_REST_HOTEL } from '../../constants';

import './ordersdialpad.scss';

const { Title } = Typography;

const Index = ({
  action,
  display,
  setDisplay,
  handleOk,
  setActionsModal,
  actionsModal,
  type,
  discType,
  setDiscType,
  isAuthenticated,
  setisAuthenticated,
}) => {
  const selectedPos = useSelector((state) => state.selectedPos.selectedPos);
  const configInfo = useSelector((state) => state.configInfo.configInfo);

  const [passInput, setPassInput] = useState('');

  const handleButtonClick = (e) => {
    const button = e.target.innerText || 'clear';

    switch (button) {
      case 'C':
        setDisplay('');
        break;
      case '+10':
        setDisplay(Number(display) + 10);
        break;
      case '+20':
        setDisplay(Number(display) + 20);
        break;
      case '+100':
        setDisplay(Number(display) + 100);
        break;
      case '+/-':
        setDisplay(-1 * Number(display));
        break;
      case 'clear':
        setDisplay(display.toString().slice(0, -1));
        break;
      default:
        setDisplay(display + e.target.innerText);
        break;
    }
  };

  const getButtonsText = () => {
    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      switch (action) {
        case 'Qty':
          return ['Disc.'];
        case 'Disc.':
          return ['Qty'];
        default:
          return ['Disc.'];
      }
    } else {
      switch (action) {
        case 'Qty':
          return ['Price', 'Disc.'];
        case 'Price':
          return ['Qty', 'Disc.'];
        case 'Disc.':
          return ['Qty', 'Price'];
        default:
          return ['Price', 'Disc.'];
      }
    }
  };

  return (
    <div>
      {((action === 'Disc.' && configInfo?.ask_password_discount) ||
        (configInfo?.ask_password_order_discount &&
          type === 'overall-discount')) &&
      !isAuthenticated ? (
        <div className='discount-pass-modal'>
          <Input
            placeholder={'Please Enter Pin'}
            value={passInput}
            type='password'
            autoComplete='new-password'
            onChange={(e) => setPassInput(e.target.value)}
          />
          <div className='btn-wrapper'>
            <Button
              type='primary'
              onClick={() => {
                if (type === 'overall-discount') {
                  if (passInput !== configInfo?.password_order_discount) {
                    message.error('Invalid Pin');
                  } else {
                    setPassInput('');
                    setisAuthenticated(true);
                  }
                } else {
                  if (passInput !== configInfo?.password_discount) {
                    message.error('Invalid Pin');
                  } else {
                    setPassInput('');
                    setisAuthenticated(true);
                  }
                }
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div className='orders-dialpad'>
          <Title level={3} className='heading'>
            {action === 'Disc.' && type === 'overall-discount'
              ? 'Global Discount'
              : actionsModal.action}
          </Title>
          {action === 'Disc.' && (
            <div className='toggle-buttons'>
              <Button
                type={discType === 'percent' && 'primary'}
                onClick={(e) => {
                  setDiscType('percent');
                }}
              >
                Percent
              </Button>
              <Button
                type={discType === 'fixed' && 'primary'}
                onClick={(e) => {
                  setDiscType('fixed');
                }}
              >
                Fixed
              </Button>
            </div>
          )}
          <div className='display'>{display}</div>
          <div className='pad'>
            <Button
              style={{ borderTopLeftRadius: '0.5rem' }}
              onClick={handleButtonClick}
            >
              1
            </Button>
            <Button onClick={handleButtonClick}>2</Button>
            <Button onClick={handleButtonClick}>3</Button>
            <Button
              style={{ borderTopRightRadius: '0.5rem' }}
              onClick={handleButtonClick}
            >
              +10
            </Button>
            <Button onClick={handleButtonClick}>4</Button>
            <Button onClick={handleButtonClick}>5</Button>
            <Button onClick={handleButtonClick}>6</Button>
            <Button onClick={handleButtonClick}>
              {action === 'Disc.' && selectedPos?.pos_type === DRIVETHRU
                ? '+100'
                : '+20'}
            </Button>
            <Button onClick={handleButtonClick}>7</Button>
            <Button onClick={handleButtonClick}>8</Button>
            <Button onClick={handleButtonClick}>9</Button>
            <Button onClick={handleButtonClick}>+/-</Button>
            <Button
              onClick={handleButtonClick}
              style={{ borderBottomLeftRadius: '0.5rem' }}
            >
              C
            </Button>
            <Button onClick={handleButtonClick}>0</Button>
            <Button onClick={handleButtonClick}>.</Button>
            <Button
              onClick={handleButtonClick}
              style={{ borderBottomRightRadius: '0.5rem' }}
            >
              <Clear />
            </Button>
          </div>
          <div
            className={`action-buttons ${
              type === 'overall-discount' && 'single-btn'
            } ${
              selectedPos?.pos_type === OIL_REST_HOTEL ? 'oil-actions' : ''
            } `}
          >
            {type !== 'overall-discount' && (
              <Button
                onClick={() => {
                  setActionsModal({
                    status: true,
                    action: getButtonsText()[0],
                  });
                  handleOk(false);
                }}
              >
                {getButtonsText()[0]}
              </Button>
            )}
            {type !== 'overall-discount' && (
              <Button
                onClick={() => {
                  setActionsModal({
                    status: true,
                    action: getButtonsText()[1],
                  });
                  handleOk(false);
                }}
              >
                {getButtonsText()[1]}
              </Button>
            )}
            <Button
              type='primary'
              onClick={() => {
                setisAuthenticated(false);
                handleOk();
              }}
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
