import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import localforage from 'localforage';

import App from './App';
import reportWebVitals from './reportWebVitals';
import appReducer from './store/reducers';
import Loader from './components/Loader';
import { RESET_STORE } from './store/types';

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'vistosPos',
});

const persistConfig = {
  key: 'state',
  storage,
  whitelist: [
    'login',
    'initialPosInfo',
    'selectedPos',
    'saveForLaterOffline',
    'selectedView',
    'configInfo',
    'previousOrders',
  ],
};

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    storage.removeItem('persist:state');
    localforage.clear('vistosPos');

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// const store = createStore(persistedReducer, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
