import { message } from 'antd';

import { callApi } from '../../service';
import { GET_CUSTOMERS, SET_CUSTOMERS } from '../../types';

const getCustomers =
  (data, customers = []) =>
  async (dispatch) => {
    if (customers?.length) {
      dispatch({ type: SET_CUSTOMERS, customers });
      return;
    }

    let concatCustomers = [];

    const getCustomers = async (last_item_id, dataT) => {
      const requestdata = {
        api_type: 'customers',
        db: data.database,
        email: data.email,
        password: data.password,
        url: data.url,
        req_arg: {
          pos_type: data.posType,
          last_item_id: last_item_id,
        },
      };
      // if (last_item_id > 0 || last_item_id !== 'dummy') {
      //   requestdata.req_arg['last_item_id'] = last_item_id;
      // }
      const tempResp = await callApi(requestdata);
      if (tempResp?.data?.error) {
        return message.error(
          `${tempResp.data.error.message}, Fetch Customers Failed!`,
          1
        );
      }
      if (
        tempResp?.data?.result?.data &&
        !tempResp?.data?.result?.data.length
      ) {
        return message.info('Oops, Customers Not Found!', 1);
      }
      concatCustomers = [...concatCustomers, ...tempResp?.data?.result?.data];
      if (
        typeof tempResp?.data?.result?.last_item_id !== Boolean &&
        tempResp?.data?.result?.last_item_id
      ) {
        getCustomers(tempResp?.data?.result?.last_item_id, dataT);
      } else {
        dispatch({ type: GET_CUSTOMERS, customers: [...concatCustomers] });
      }
    };
    await getCustomers(0, data);
  };

export { getCustomers };
